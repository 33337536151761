<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ pageTitleText }}</h3>
      </AppCardHeader>
    </template>

    <XeAddressSearch
      :page-name="pageNameClassname"
      :dropdown-label="dropdownLabelText"
      data-private
      @select="onSelect"
      @manual="onEnterManuallyClick('Dropdown')"
      @searchFinish="onSearchFinish($event)"
    >
      <template #custom-dropdown-footer-option>
        <div class="custom-dropdown-footer">
          Don't see your address?
          <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
          <a class="custom-footer-button" @click="onEnterManuallyClick('Dropdrown')"
            >Enter it manually</a
          >
        </div>
      </template>
    </XeAddressSearch>
    <div class="manually-enter-section">
      <h2 class="text-gray">or you can</h2>
      <AppButton theme="text" class="manual-button" @click="onEnterManuallyClick('Screen')">
        enter manually
      </AppButton>
    </div>
  </AppCard>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { extractStreetNameAndStreetType } from '@/composables/useAddress'
import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import XeAddressSearch from '@/components/XeAddressSearch/XeAddressSearch'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '../../stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'

export const searchTypes = Object.freeze({
  business: {
    pageTitle: 'Business address',
    dropdownLabelText: "Company's official address",
    routerName: 'BusinessAddress',
    backUrlName: 'RegisterBusinessDetails',
    pageNameClassname: 'businessaddrsearch',
    dispatchSaveAddressTo: 'companyAddress',
    groupName: 'registeredaddress',
    progressStepId: 2,
    analyticsName: 'Business',
  },
  personal: {
    pageTitle: 'Your address',
    dropdownLabelText: 'Home address',
    routerName: 'PersonalAddress',
    backUrlName: 'RegisterPersonalDetails',
    pageNameClassname: 'personaladdrsearch',
    dispatchSaveAddressTo: 'personalAddress',
    groupName: 'contactaddress',
    progressStepId: 3,
    analyticsName: 'Personal',
  },
  operatingBusiness: {
    pageTitle: 'Operating address',
    dropdownLabelText: 'Business operating address',
    routerName: 'OperatingAddress',
    backUrlName: 'BusinessAddress',
    pageNameClassname: 'operatingaddrsearch',
    dispatchSaveAddressTo: 'companyOperatingAddress',
    groupName: 'tradingaddress',
    progressStepId: 2,
    analyticsName: 'Operating',
  },
})

export default {
  name: 'RegisterAddressSearch',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppIcon,
    IconChevronLeft,
    XeAddressSearch,
  },
  props: {
    searchType: {
      type: String,
      default: 'business',
      validator: (value) => {
        return Object.hasOwn(searchTypes, value)
      },
    },
  },
  setup(props) {
    const analyticsStore = useAnalyticsStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()

    const router = useRouter()
    const authStore = useAuthStore()

    const searchType = searchTypes[props.searchType]
    let pageTitleText = searchType.pageTitle
    const dropdownLabelText = searchType.dropdownLabelText
    const pageNameClassname = searchType.pageNameClassname
    const getCountry = companyDetailsStore.companyCountry

    if (getCountry === 'GB' || getCountry === 'MY') {
      if (searchType.groupName === 'registeredaddress') {
        pageTitleText = 'Registered address'
      }
      if (searchType.groupName === 'tradingaddress') {
        pageTitleText = 'Business address'
      }
    }

    // Modify routes if search type is personal and company type are sole proprietorship, trader, or other
    const companyTypeName = corporateRegistrationStore.companyTypeName.toLocaleLowerCase()
    if (
      props.searchType === 'personal' &&
      (companyTypeName === 'sole proprietorship' ||
        companyTypeName === 'sole trader' ||
        companyTypeName === 'other')
    ) {
      searchType.backUrlName = 'RegisterPersonalAddressSelection'
    }

    corporateRegistrationStore.activeStepIdx = searchType.progressStepId
    corporateRegistrationStore.setActiveStepPageTitle(pageTitleText)

    let lastDuration = 0
    const onSearchFinish = (result) => {
      lastDuration = result.duration
    }

    const onSelect = async (address) => {
      companyDetailsStore[searchType.dispatchSaveAddressTo] = address
      address.provinceName = address.provinceName.replace(/Wp\s/, '')
      let state = corporateRegistrationStore.mapStateValue(address.provinceName)
      // set state to null if not mapped correctly
      const stateLength = state ? state.length : 0
      state = stateLength <= 3 ? state : null
      const [streetName, streetType] = extractStreetNameAndStreetType(address.street)

      const formData = [
        // free-format address
        { id: 'line1', value: address.addressLine1 },
        { id: 'line2', value: address.addressLine2 },
        { id: 'line3', value: address.addressLine3 },
        // fixed-format address
        { id: 'streetname', value: streetName },
        { id: 'streetnumber', value: address.buildingNumber },
        { id: 'streettype', value: streetType },
        { id: 'unit', value: address.subBuilding },
        { id: 'area', value: address?.area },
        // shared
        { id: 'city', value: address.city },
        { id: 'state', value: state },
        { id: 'postalcode', value: address.postCode },
        { id: 'country', value: address.countryCode },
      ]

      analyticsStore.track({
        event: 'Address Search Completed',
        traits: {
          addressType: searchType.analyticsName,
          enterManually: false,
          enterManuallyLocation: '',
          searchDuration: lastDuration,
          referenceSource: 'XEMT Business',
          email: authStore.lastLogin,
        },
      })
      corporateRegistrationStore.setCorporateFormFieldValue({
        name: searchType.groupName,
        formData,
      })
      router.push({ name: searchType.routerName })
    }

    const onEnterManuallyClick = (analyticsName) => {
      analyticsStore.track({
        event: 'Address Search Completed',
        traits: {
          addressType: searchType.analyticsName,
          enterManually: true,
          enterManuallyLocation: analyticsName,
          searchDuration: '',
          referenceSource: 'XEMT Business',
          email: authStore.lastLogin,
        },
      })
      router.push({ name: searchType.routerName })
    }

    const goBack = () => {
      if (searchType.backUrlName) {
        router.push({ name: searchType.backUrlName })
      } else {
        router.go(-1)
      }
    }

    return {
      dropdownLabelText,
      onSelect,
      onEnterManuallyClick,
      onSearchFinish,
      pageNameClassname,
      pageTitleText,
      goBack,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.manually-enter-section {
  @apply flex justify-center;
  @apply text-base;

  .button {
    @apply ml-1;

    ::v-deep &.button--text {
      .button-inner {
        @apply p-0;
      }
    }

    &:focus,
    &:hover {
      @apply bg-transparent;
    }
  }
}

.custom-dropdown-footer {
  @apply px-4 py-2.5;
  @apply type-caption;
  @apply cursor-default;

  .custom-footer-button {
    @apply text-blue;
    @apply cursor-pointer;
  }
}
</style>
